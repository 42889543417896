import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import {
  Heading,
  Grid,
  Text,
  Input,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Flex,
  Textarea,
  Spinner,
} from '@chakra-ui/react'
import { useMachine } from '@xstate/react'
import { fetchMachine, events } from '../utils/fetch.machine'
import { request } from '../utils/request'
import { useForm } from 'react-hook-form'

const saveContactMessage = (ctx: any, evt: any) => {
  return request(
    {
      method: 'post',
      url: '/v1/contact',
      data: evt.payload,
    },
    false
  )
}

const ContactPage = () => {
  const [currentState, send] = useMachine(fetchMachine, {
    services: {
      fetchData: saveContactMessage,
    },
  })

  const { register, handleSubmit, formState: { errors } } = useForm()

  const onSubmit = data => {
    send({
      type: events.FETCH,
      payload: { ...data },
    })
  }

  const isLoading = currentState.matches('loading')
  const hasSuccess = currentState.matches('success')
  const hasError = currentState.matches('error')

  const hasEmailFormError = errors.email
  const hasMessageFormError = errors.message
  const hasNameFormError = errors.fullName

  // TODO: Refactor form invalid. Just use setError from RHF

  return (
    <Layout>
      <SEO title="Contact Us" />
      <Heading mb="5">Contact Us</Heading>
      <Text mb="8" maxWidth="xl" textAlign="center">
        Have questions or suggestions on how we can improve our service? We'll
        be happy to answer your questions about our service.
      </Text>
      {hasSuccess ? (
        <Flex
          maxWidth="md"
          p="5"
          borderColor="green.800"
          bg="green.200"
          color="green.800"
        >
          Message sent. We'll try and get back to you as soon as possible. Thank
          you
        </Flex>
      ) : (
        <Grid
          mt="12"
          maxWidth="lg"
          width="100%"
          borderWidth="1px"
          rounded="lg"
          p="6"
        >
          <FormControl
            as="form"
            onSubmit={handleSubmit(onSubmit)}
            isInvalid={hasError}
          >
            <FormLabel htmlFor="fullName">Full Name</FormLabel>
            {hasNameFormError && (
              <Text color="red.300" fontSize="xs">
                {errors.fullName.message}
              </Text>
            )}
            <Input
              variant="flushed"
              placeholder="Mary Smith"
              mb={4}
              {...register('fullName', { required: 'Name is required' })}
            />
            <FormLabel htmlFor="email">Email</FormLabel>
            {hasEmailFormError && (
              <Text color="red.300" fontSize="xs">
                {errors.email.message}
              </Text>
            )}
            <Input
              variant="flushed"
              // type="email"
              placeholder="hey@email.com"
              {...register('email', {
                required: 'Email is required', pattern: {
                  value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: 'Invalid email address'
                }
              })}
              mb={4}
            />
            <FormLabel htmlFor="message">Message</FormLabel>
            {hasMessageFormError && (
              <Text color="red.300" fontSize="xs" mb={2}>
                {errors.message.message}
              </Text>
            )}
            <Textarea
              placeholder="Your message here..."
              {...register('message', { required: 'Message is required' })}
              mb={5}
            />
            <FormErrorMessage mb="5">
              {currentState?.context?.error}
            </FormErrorMessage>
            <Flex justifyContent="flex-end">
              <Button type="submit" justifySelf="end">
                {isLoading ? <Spinner /> : 'Send message'}
              </Button>
            </Flex>
          </FormControl>
        </Grid>
      )}
    </Layout>
  )
}

export default ContactPage
